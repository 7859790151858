export const initialStations = [
  { id: 0, name: "AKSU", params: "2Р=1.1с", isActive: false, coord: { lat: 42.831724, lng: 74.10874 } },
  { id: 1, name: "ANAN", params: "2Р=1.1с", isActive: false, coord: { lat: 42.73683, lng: 77.67479 } },
  { id: 2, name: "CHAK", params: "2Р=1.1с", isActive: false, coord: { lat: 41.926895, lng: 74.518496 } },
  { id: 3, name: "GLHA", params: "2Р=1.1с", isActive: false, coord: { lat: 40.316638, lng: 73.440965 } },
  { id: 4, name: "JANJ", params: "2Р=1.1с", isActive: false, coord: { lat: 43.106672, lng: 74.375363 } },
  { id: 5, name: "KAJS", params: "2Р=1.1с", isActive: false, coord: { lat: 42.141074, lng: 77.180088 } },
  { id: 6, name: "KAKU", params: "2Р=1.1с", isActive: false, coord: { lat: 40.635023, lng: 73.586906 } },
  { id: 7, name: "KAYN", params: "2Р=1.1с", isActive: false, coord: { lat: 42.829122, lng: 73.683476 } },
  { id: 8, name: "KCHK", params: "2Р=1.1с", isActive: false, coord: { lat: 42.212106, lng: 75.752574 } },
  { id: 9, name: "KKOL", params: "2Р=1.1с", isActive: false, coord: { lat: 42.500864, lng: 78.389056 } },
  { id: 10, name: "KYZA", params: "2Р=1.1с", isActive: false, coord: { lat: 40.259566, lng: 72.125665 } },
  { id: 11, name: "KZSU", params: "2Р=1.1с", isActive: false, coord: { lat: 42.341926, lng: 78.014702 } },
  { id: 12, name: "MLSU", params: "2Р=1.1с", isActive: false, coord: { lat: 41.270332, lng: 72.467717 } },
  { id: 13, name: "NKAT", params: "2Р=1.1с", isActive: false, coord: { lat: 40.231539, lng: 72.671077 } },
  { id: 14, name: "OKTR", params: "2Р=1.1с", isActive: false, coord: { lat: 41.04617, lng: 73.110787 } },
  { id: 15, name: "TALS", params: "2Р=1.1с", isActive: false, coord: { lat: 42.517436, lng: 72.251072 } },
  { id: 16, name: "TKMK", params: "2Р=1.1с", isActive: false, coord: { lat: 42.835621, lng: 75.28367 } },
  { id: 17, name: "TMCH", params: "2Р=1.1с", isActive: false, coord: { lat: 42.564789, lng: 76.662488 } },
]
